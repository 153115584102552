import http from '../http'
import {
  BuildingType,
  Project,
  Module,
  AssessmentsListParams,
  ProjectsListParams,
  Assessment,
  Question,
  AssessmentFormData,
  ProjectFormData,
  QuestionScoreParams,
  QuestionScrore,
  ReportConstants,
  Benchmark,
  DesignStage,
  PrecinctReportConstants,
} from '../types'
import { API_URL } from './urls'

export const CalculatorsApi = {
  projectsList: async function (
    params: ProjectsListParams
  ): Promise<{ count: number; results: Project[] }> {
    return await http.get(API_URL.calculatorsProjects, { params })
  },

  projectDetails: async function (slug: string): Promise<Project> {
    return await http.get(`${API_URL.calculatorsProjects}${slug}/`)
  },

  createProject: async function (data: ProjectFormData): Promise<Project> {
    return await http.post(API_URL.calculatorsProjects, data)
  },

  updateProject: async function (data: ProjectFormData): Promise<Project> {
    return await http.patch(`${API_URL.calculatorsProjects}${data.slug}/`, data)
  },

  deleteProject: async function (slug: string) {
    return await http.delete(`${API_URL.calculatorsProjects}${slug}/`)
  },

  buildingTypes: async function (params?: {
    calculator_module: string
  }): Promise<BuildingType[]> {
    return await http.get(API_URL.buildingTypes, { params })
  },

  modules: async function (): Promise<Module[]> {
    return await http.get(API_URL.calculatorsModules)
  },

  moduleDetails: async function (slug: string): Promise<Module> {
    return await http.get(`${API_URL.calculatorsModules}${slug}/`)
  },

  asssessmentsList: async function (
    params: AssessmentsListParams
  ): Promise<{ count: number; results: Assessment[] }> {
    return await http.get(API_URL.calculatorsAssessments, { params })
  },

  asssessmentDetails: async function (id: string): Promise<Assessment> {
    return await http.get(`${API_URL.calculatorsAssessments}${id}/`)
  },

  createAssessment: async function (
    data: AssessmentFormData
  ): Promise<Assessment> {
    return await http.post(API_URL.calculatorsAssessments, data)
  },

  updateAssessment: async function (
    data: AssessmentFormData
  ): Promise<Assessment> {
    return await http.patch(
      `${API_URL.calculatorsAssessments}${data.id}/`,
      data
    )
  },

  deleteAssessment: async function (id: number) {
    return await http.delete(`${API_URL.calculatorsAssessments}${id}/`)
  },

  questionDetails: async function (id: number): Promise<Question> {
    return await http.get(`${API_URL.calculatorsQuestions}${id}/`)
  },

  transportGroups: async function (): Promise<{ transport_groups: string[] }> {
    return await http.get(API_URL.transportGroups)
  },

  requestEvaluation: async function (
    assessmentId: number,
    client_notes: string
  ): Promise<Assessment> {
    return await http.post(
      `${API_URL.calculatorsAssessments}${assessmentId}/request-evaluation/`,
      { client_notes }
    )
  },

  questionScore: async function (
    params: QuestionScoreParams
  ): Promise<QuestionScrore> {
    return await http.get(API_URL.questionScore, { params })
  },

  excelReport: async function (id: number): Promise<{ url: string }> {
    return await http.get(
      `${API_URL.calculatorsAssessments}${id}/excel-report/`
    )
  },
  excelEcoReport: async function (id: number): Promise<{ url: string }> {
    return await http.get(
      `${API_URL.calculatorsAssessments}${id}/excel-eco-report/`
    )
  },

  reportingConstants: async function (): Promise<ReportConstants> {
    return await http.get(API_URL.reportingConstants)
  },

  precinctReportingConstants: async function (): Promise<PrecinctReportConstants> {
    return await http.get(API_URL.precinctReportingConstants)
  },

  assessmentsBenchmarks: async function (
    id: number | string
  ): Promise<Benchmark[]> {
    return await http.get(`${API_URL.calculatorsAssessments}${id}/benchmarks/`)
  },

  designStages: async function (): Promise<DesignStage[]> {
    return await http.get(API_URL.calculatorsDesignStages)
  },

  ecoReportingConstants: async function (): Promise<any> {
    return await http.get(API_URL.ecoReportingConstants)
  },

  precinctEcoReportingConstants: async function (): Promise<any> {
    return await http.get(API_URL.precinctEcoReportingConstants)
  },
}
