import _cloneDeep from 'lodash/cloneDeep'
import _difference from 'lodash/difference'
import _uniqBy from 'lodash/uniqBy'
import _groupBy from 'lodash/groupBy'
import _intersectionWith from 'lodash/intersectionWith'
import _isEqual from 'lodash/isEqual'
import _xor from 'lodash/xor'
import router from 'next/router'
import { Answer } from '../components/Assessments/Details/CalForm/TransportSection/GroupMode'
import { Group } from '../components/Assessments/Details/CalForm/TransportSection/TransportSection'
import {
  AssessmentAnswer,
  AssessmentsFiltersParams,
  AssessmentsListParams,
  Benchmark,
  BenchmarkCarbonData,
  CalculatorSection,
  CarbonBreakdownSection,
  Choice,
  MaterialSelection,
  ProjectsListParams,
  Question,
  QuestionWithAnswers,
  ValueUnit,
} from './types'
import _filter from 'lodash/filter'
import * as lodash from 'lodash'
import { convertToneToKg } from '../lib/helpers'

export const reloadProjectsList = async (
  params: ProjectsListParams,
  setPageLoading?: (val: boolean) => void
) => {
  setPageLoading && setPageLoading(true)
  await router.push({
    pathname: '/projects',
    query: params,
  })
  setPageLoading && setPageLoading(false)
}

export const reloadAssessmentsList = async (
  slug: string,
  params: AssessmentsListParams,
  setPageLoading?: (val: boolean) => void
) => {
  setPageLoading && setPageLoading(true)
  await router.push({
    pathname: `/projects/${slug}`,
    query: params,
  })
  setPageLoading && setPageLoading(false)
}

export const getInitAnswersFromQuestionId = (
  answers: AssessmentAnswer[] = [],
  questionId: number,
  subQuestionId: number | null,
  valueUnit: String | null,
  choices?: Choice[]
) => {
  const initAnswers = answers
    .filter(
      (it) =>
        it.question_id === questionId && it.sub_question_id == subQuestionId
    )
    .map((answer) => {
      return {
        ...answer,
        sub_question_id: answer.sub_question_id || null,
      }
    })

  if (initAnswers.length > 0) {
    if (choices && choices.length > 0) {
      initAnswers.forEach((it) => {
        const selected = choices.find((choice) => choice.id === it.item)
        it.variant = `${selected?.material}__${selected?.variant}`
        it.location = selected?.location
      })
    }
    return initAnswers
  }

  return [
    {
      question_id: questionId,
      value_unit: valueUnit,
      item: null,
      value: null,
      lifetime: null,
      sub_question_id: subQuestionId ? subQuestionId : null,
    },
  ]
}

export const getInitAnswersFromChoices = (
  answers: AssessmentAnswer[] = [],
  questionId: number,
  subQuestionId: number | null,
  choices: Choice[]
) => {
  const initQuestions = choices.map((choice) => {
    const answer = answers.find((answer) => answer.item === choice.full_code)

    if (answer?.question_id)
      return {
        ...answer,
        sub_question_id: subQuestionId ? subQuestionId : null,
      }

    return {
      question_id: questionId,
      value_unit: choice.measurement,
      item: choice.full_code,
      value: null,
      lifetime: null,
      sub_question_id: subQuestionId ? subQuestionId : null,
    }
  })

  return initQuestions
}

export const getInitGroupsFromQuestions = (
  answers: AssessmentAnswer[] = [],
  questions: Question[],
  choices: Choice[]
) => {
  const getInitGroupData = (question: Question, choices: Choice[]) => {
    const answersData = answers
      .filter((it) => it.question_id === question.id && it.item)
      .map((answer) => {
        const choice = choices.find((it) => it.full_code === answer.item)
        return {
          ...choice,
          total_people: answer?.extra_data?.total_people,
          avg_trips: answer?.extra_data?.avg_trips,
          avg_trip_distance: answer?.extra_data?.avg_trip_distance,
        }
      })

    return {
      id: question.id,
      text: question.text,
      answers: answersData,
    } as Group
  }

  const groups = questions
    .map((it) => {
      const group = getInitGroupData(it, choices)
      return group
    })
    .filter((it) => it.answers.length > 0)

  return groups || []
}

export const getModCO2 = ({
  total_people,
  avg_trips,
  avg_trip_distance,
  tco2_per_unit,
}: Answer) => {
  if (total_people && avg_trips && avg_trip_distance && tco2_per_unit) {
    return (
      parseFloat(total_people.toString()) *
      parseFloat(avg_trips.toString()) *
      parseFloat(avg_trip_distance.toString()) *
      tco2_per_unit
    ).toFixed(2)
  }
  return ''
}

export const getValuesFromGroupsData = (
  groups: Group[],
  questions: Question[]
) => {
  let anwsersData: AssessmentAnswer[] = []
  groups.forEach((group) => {
    const answers: AssessmentAnswer[] = group.answers.map((it) => {
      const value = getModCO2(it)
      return {
        question_id: group.id,
        item: it.full_code,
        value: value ? parseFloat(value).toFixed(2) : '',
        value_unit: 'm3',
        extra_data: {
          total_people: it.total_people,
          avg_trips: it.avg_trips,
          avg_trip_distance: it.avg_trip_distance,
        },
      }
    })
    anwsersData = anwsersData.concat(answers)
  })
  anwsersData.filter((it) => it.value !== null)

  const questionIds = questions.map((it) => it.id)
  const anwserIds = _uniqBy(anwsersData, 'question_id').map(
    (it) => it.question_id
  )
  const emptyAnwserIds = _xor(questionIds, anwserIds)
  if (emptyAnwserIds.length > 0) {
    const emptyAnwsersData = emptyAnwserIds.map((it) => {
      return { question_id: it }
    })
    return anwsersData.concat(emptyAnwsersData as AssessmentAnswer[])
  }

  return anwsersData
}

export const getSectionStatus = (
  section: CalculatorSection,
  answers: AssessmentAnswer[] = [],
  sectionIssues: string[] = []
) => {
  let status = ''
  const childSections = section.childs?.map((it) => it.name) || []

  if (
    sectionIssues.indexOf(section.name) > -1 ||
    _intersectionWith(sectionIssues, childSections, _isEqual).length > 0
  ) {
    return 'error'
  }

  if (answers.length === 0 || section.questions.length === 0) return status
  const answerQuestionIds: number[] = _uniqBy(answers, 'question_id').map(
    (it) => it.question_id
  )
  const answerSubQuestionIds = _uniqBy(answers, 'sub_question_id')
    .map((it) => it.sub_question_id)
    .filter((it) => it !== undefined) as number[]

  let questionsNotAnswers = []
  let requiredQuestionsNotAnswers = []
  let subQuestionsNotAnswers = []
  let requiredSubQuestionsNotAnswers = []

  questionsNotAnswers = _difference(section.questionIds, answerQuestionIds)
  if (section.subQuestionIds.length > 0) {
    subQuestionsNotAnswers = _difference(
      section.subQuestionIds,
      answerSubQuestionIds
    )

    if (section.requiredSubQuestionIds.length > 0) {
      requiredSubQuestionsNotAnswers = _difference(
        section.requiredSubQuestionIds,
        answerSubQuestionIds
      )
    }
  }

  if (
    (questionsNotAnswers.length > 0 &&
      questionsNotAnswers.length < section.questionIds.length) ||
    (requiredQuestionsNotAnswers.length > 0 &&
      requiredQuestionsNotAnswers.length <
        section.requiredQuestionIds.length) ||
    (subQuestionsNotAnswers.length > 0 &&
      subQuestionsNotAnswers.length < section.subQuestionIds.length) ||
    (requiredSubQuestionsNotAnswers.length > 0 &&
      requiredSubQuestionsNotAnswers.length <
        section.requiredSubQuestionIds.length)
  ) {
    status = 'loading'
  }

  if (section.requiredQuestionIds.length > 0) {
    requiredQuestionsNotAnswers = _difference(
      section.requiredQuestionIds,
      answerQuestionIds
    )

    if (section.subQuestionIds.length > 0) {
      if (section.requiredSubQuestionIds.length > 0) {
        if (
          (questionsNotAnswers.length === 0 ||
            requiredQuestionsNotAnswers.length === 0) &&
          (subQuestionsNotAnswers.length === 0 ||
            requiredSubQuestionsNotAnswers.length == 0)
        ) {
          return 'checked'
        }
      } else {
        if (
          (questionsNotAnswers.length === 0 ||
            requiredQuestionsNotAnswers.length === 0) &&
          subQuestionsNotAnswers.length === 0
        ) {
          return 'checked'
        }
      }
    } else {
      if (
        questionsNotAnswers.length === 0 ||
        requiredQuestionsNotAnswers.length == 0
      ) {
        return 'checked'
      }
    }
  } else {
    if (section.subQuestionIds.length > 0) {
      if (section.requiredSubQuestionIds.length > 0) {
        if (
          questionsNotAnswers.length === 0 &&
          (requiredSubQuestionsNotAnswers.length === 0 ||
            subQuestionsNotAnswers.length == 0)
        ) {
          return 'checked'
        }
      } else {
        if (
          questionsNotAnswers.length === 0 &&
          subQuestionsNotAnswers.length == 0
        ) {
          return 'checked'
        }
      }
    } else {
      if (questionsNotAnswers.length === 0) {
        return 'checked'
      }
    }
  }

  return status
}

export const getTotalWaterFromAnswers = (values: any) => {
  let answers = Object.keys(values)
    .map((key) => {
      return values[key]
    })
    .reduce((a, b) => a.concat(b), [])
    .filter((it: any) => it && it.item && it.value) as AssessmentAnswer[]

  const total = answers.reduce(
    (prev, curr) => prev + parseFloat(curr.value ? curr.value.toString() : '0'),
    0
  )

  return total
}

export const isVisibleQuestion = (
  question: Question,
  choices: Choice[],
  answers: AssessmentAnswer[],
  params: AssessmentsFiltersParams
) => {
  let isVisible = true

  const hasAnswer =
    answers?.length > 0 &&
    answers.filter((it) => it?.item || it?.value).length > 0

  if (
    params.field &&
    (!question.sub_questions?.length || question.sub_questions?.length === 0)
  ) {
    isVisible =
      question.text.toLowerCase().indexOf(params.field.toLowerCase()) > -1

    if (isVisible === false) return false
  }
  if (params.empty_fields === '0') {
    isVisible = !hasAnswer
    if (isVisible === false) return false
  } else if (params.empty_fields === '1') {
    isVisible = hasAnswer
    if (isVisible === false) return false
  }

  if (params.country) {
    if (
      question.choices &&
      question.choices.length > 0 &&
      question.choices.filter(
        (it) => it.location.toLowerCase() === params.country?.toLowerCase()
      ).length > 0
    ) {
    } else {
      return false
    }
  }

  return isVisible
}

export const isVisibleOption = (
  choice: Choice,
  params: AssessmentsFiltersParams
) => {
  let isVisible = true

  if (params.unit) {
    isVisible = choice.measurement === params.unit
    if (isVisible === false) return false
  }

  if (params.country) {
    isVisible = choice.location.toLowerCase() === params.country.toLowerCase()
    if (isVisible === false) return false
  }

  if (params.co2e) {
    const ranges = params.co2e.split('_')
    if (ranges[0] === '') {
      isVisible = choice.carbon_rating < parseFloat(ranges[1])
    } else if (ranges[1] === '') {
      isVisible =
        choice.carbon_rating < parseFloat(ranges[1]) &&
        choice.carbon_rating > parseFloat(ranges[0])
    } else {
      isVisible = choice.carbon_rating > parseFloat(ranges[0])
    }
  }

  return isVisible
}

export const isVisibleGroup = (
  group: Group,
  params: AssessmentsFiltersParams
) => {
  let isVisible = true

  if (params.field) {
    isVisible =
      group.text.toLowerCase().indexOf(params.field.toLowerCase()) > -1
  }

  const hasAnswer =
    group.answers.filter(
      (answer) =>
        answer.total_people && answer.avg_trip_distance && answer.avg_trips
    ).length > 0

  if (params.empty_fields === '0') {
    isVisible = !hasAnswer
  } else if (params.empty_fields === '1') {
    isVisible = hasAnswer
  }

  return isVisible
}

export const isVisibleMode = (
  answer: Answer,
  params: AssessmentsFiltersParams
) => {
  let isVisible = true

  const hasAnswer =
    answer.total_people && answer.avg_trip_distance && answer.avg_trips
      ? true
      : false

  if (params.empty_fields === '0') {
    isVisible = !hasAnswer
  } else if (params.empty_fields === '1') {
    isVisible = hasAnswer
  }

  return isVisible
}

export const getAssessmentProgress = (
  sections: CalculatorSection[],
  answers?: AssessmentAnswer[]
) => {
  let progress = 0
  const validSections = sections.filter(
    (it) => it.questions.length > 0 || it.childs.length > 0
  )
  const percentPerSection = 100 / validSections.length

  validSections.forEach((section) => {
    if (section.childs.length > 0) {
      let totalCheckedChildSection = 0
      section.childs.forEach((cSection) => {
        const status = getSectionStatus(cSection, answers)
        if (status === 'checked') {
          totalCheckedChildSection += 1
        }
      })
      progress +=
        (percentPerSection / section.childs.length) * totalCheckedChildSection
    } else {
      const status = getSectionStatus(section, answers)
      if (status === 'checked') {
        progress += percentPerSection
      }
    }
  })

  return parseFloat(progress.toFixed(1))
}

export const getMaterialTableBreakdown = (
  sections: CalculatorSection[],
  answers?: AssessmentAnswer[],
  isKgCo2Unit?: boolean,
) => {
  let materialChartData = _cloneDeep(sections)

  const getAnswersByQuestionId = (id: number) => {
    return answers?.filter((it) => it.question_id === id)
  }

  materialChartData.forEach((section) => {
    section['carbonCostNFA'] = 0
    section['ecoDemandTotal'] = 0
    section['ecoDemandGFA'] = 0
    section['ecoDemandNFA'] = 0
    section['carbonCost'] = 0
    section['carbonCostGFA'] = 0

    // Stage B (B4/B5)
    section['lifetimeCost'] = 0
    section['lifetimeCostNFA'] = 0
    section['lifetimeCostGFA'] = 0
    // section['totalStars'] = 0

    section.questions.forEach((question) => {
      const answersData = getAnswersByQuestionId(question.id)

      if (answersData && answersData.length > 0) {
        answersData?.forEach((it: any) => {
          section.carbonCostNFA +=
            (it.carbon_cost_units && it.carbon_cost_units[0]) || 0
          section.ecoDemandTotal += it.eco_demand_total
          section.ecoDemandNFA +=
            (it.eco_demand_units && it.eco_demand_units[0]) || 0
          section.ecoDemandGFA +=
            (it.eco_demand_units && it.eco_demand_units[1]) || 0
          section.carbonCost += it.carbon_cost
          section.carbonCostGFA +=
            (it.carbon_cost_units && it.carbon_cost_units[1]) || 0

          // Stage B (B4/B5)
          section.lifetimeCost += it.lifetime_carbon_cost
          section.lifetimeCostNFA +=
            (it.lifetime_cost_units && it.lifetime_cost_units[0]) || 0
          section.lifetimeCostGFA +=
            (it.lifetime_cost_units && it.lifetime_cost_units[1]) || 0
          // const starRating = getStarRating(
          //   section.threeStarBenchmark,
          //   it.carbon_cost_nfa
          // )
          // section.totalStars += starRating
          // if (starRating) {
          //   totalAnswersHaveStarRating += 1
          // }
        })
      }
    })

    if (section.childs && section.childs.length > 0) {
      section.childs.forEach((child) => {
        // child.totalStars = 0
        child.carbonCostNFA = 0
        child.carbonCost = 0
        child.carbonCostGFA = 0

        child.lifetimeCost = 0
        child.lifetimeCostGFA = 0
        child.lifetimeCostNFA = 0

        child.ecoDemandTotal = 0
        child.ecoDemandGFA = 0
        child.ecoDemandNFA = 0

        child.questions.forEach((cQuestion) => {
          const cAnswersData = getAnswersByQuestionId(cQuestion.id)

          if (cAnswersData && cAnswersData.length > 0) {
            cAnswersData?.forEach((it: any) => {

              child.carbonCostNFA +=
                (it.carbon_cost_units && it.carbon_cost_units[0]) || 0
              child.ecoDemandTotal += it.eco_demand_total
              child.ecoDemandNFA +=
                (it.eco_demand_units && it.eco_demand_units[0]) || 0
              child.ecoDemandGFA +=
                (it.eco_demand_units && it.eco_demand_units[1]) || 0
              child.carbonCost += it.carbon_cost
              child.carbonCostGFA +=
                (it.carbon_cost_units && it.carbon_cost_units[1]) || 0
                
              // const starRating = getStarRating(
              //   section.threeStarBenchmark,
              //   it.carbon_cost_nfa
              // )
              // child.totalStars += starRating
              section.carbonCostNFA +=
                (it.carbon_cost_units && it.carbon_cost_units[0]) || 0
              section.ecoDemandTotal += it.eco_demand_total
              section.ecoDemandNFA +=
                (it.eco_demand_units && it.eco_demand_units[0]) || 0
              section.ecoDemandGFA +=
                (it.eco_demand_units && it.eco_demand_units[1]) || 0
              section.carbonCost += it.carbon_cost
              section.carbonCostGFA +=
                (it.carbon_cost_units && it.carbon_cost_units[1]) || 0
              // section.totalStars += starRating
              // if (starRating) {
              //   totalAnswersHaveStarRating += 1
              //   totalAnswersChildSectionHaveStarRating += 1
              // }

              // Stage B (B4/B5)
              child.lifetimeCost += it.lifetime_carbon_cost
              child.lifetimeCostNFA +=
                (it.lifetime_cost_units && it.lifetime_cost_units[0]) || 0
              child.lifetimeCostGFA +=
                (it.lifetime_cost_units && it.lifetime_cost_units[1]) || 0

              section.lifetimeCost += it.lifetime_carbon_cost
              section.lifetimeCostNFA +=
                (it.lifetime_cost_units && it.lifetime_cost_units[0]) || 0
              section.lifetimeCostGFA +=
                (it.lifetime_cost_units && it.lifetime_cost_units[1]) || 0
            })
          }
        })

        // child['starRating'] = child.totalStars
        //   ? child.totalStars / totalAnswersChildSectionHaveStarRating
        //   : 0
      })
    }

    // section['starRating'] = section.totalStars
    //   ? section.totalStars / totalAnswersHaveStarRating
    //   : 0
  })

  return materialChartData.map((it) => {
    let childs: any = []
    let carbonCostNFADisplay = 0
    if (it.childs) {
      childs = it.childs.map((child) => {
        return {
          name: child.name,
          carbonCostNFA: convertToneToKg(child.carbonCostNFA, isKgCo2Unit),
          starRating: child.starRating,
          threeStarBenchmark: convertToneToKg(child.threeStarBenchmark, isKgCo2Unit),
          ecoDemandTotal: child.ecoDemandTotal,
          ecoDemandNFA: child.ecoDemandNFA,
          ecoDemandGFA: child.ecoDemandGFA,
          carbonCost: convertToneToKg(child.carbonCost, isKgCo2Unit),
          carbonCostGFA: convertToneToKg(child.carbonCostGFA, isKgCo2Unit),
          carbonCostNFADisplay: convertToneToKg(child.carbonCostNFA, isKgCo2Unit).toFixed(2),
          lifetimeCost: convertToneToKg(child.lifetimeCost, isKgCo2Unit),
          lifetimeCostNFA: convertToneToKg(child.lifetimeCostNFA, isKgCo2Unit),
          lifetimeCostGFA: convertToneToKg(child.lifetimeCostGFA, isKgCo2Unit),
          scope: it.scope,
          colour: child.colour,
          isOperational: child.calculation_type == 'operational',
          sectionType: child.section_type,
          ecologicalBenchmark: child.ecologicalBenchmark,
        }
      })

      carbonCostNFADisplay = childs.reduce(function (
        accumulator: number,
        curValue: {
          carbonCostNFADisplay: number
        }
      ) {
        return (
          accumulator + parseFloat(curValue.carbonCostNFADisplay?.toString())
        )
      }, 0)
    }

    return {
      name: it.name,
      carbonCostNFA: convertToneToKg(it.carbonCostNFA, isKgCo2Unit),
      starRating: it.starRating,
      childs: childs,
      threeStarBenchmark: convertToneToKg(it.threeStarBenchmark, isKgCo2Unit),
      ecoDemandTotal: it.ecoDemandTotal,
      ecoDemandNFA: it.ecoDemandNFA,
      ecoDemandGFA: it.ecoDemandGFA,
      carbonCost: convertToneToKg(it.carbonCost, isKgCo2Unit),
      carbonCostGFA: convertToneToKg(it.carbonCostGFA, isKgCo2Unit),
      carbonCostNFADisplay:
        it.childs.length > 0 ? carbonCostNFADisplay : (convertToneToKg(it.carbonCostNFA, isKgCo2Unit)),
      scope: it.scope,
      lifetimeCost: convertToneToKg(it.lifetimeCost, isKgCo2Unit),
      lifetimeCostNFA: convertToneToKg(it.lifetimeCostNFA, isKgCo2Unit),
      lifetimeCostGFA: convertToneToKg(it.lifetimeCostGFA, isKgCo2Unit),
      colour: it.colour,
      isOperational: it.calculation_type == 'operational',
      sectionType: it.section_type,
      ecologicalBenchmark: it.ecologicalBenchmark,
    }
  })
}

export const getMaterialSelectionFromChoices = (choices: Choice[]) => {
  let groups: MaterialSelection[] = []

  const isMaterialOptions = choices.find((it) => it.material)?.id

  if (!isMaterialOptions) {
    return []
  } else {
    choices.forEach((choice) => {
      let nChoice = _cloneDeep(choice)
      const groupIdx = groups.findIndex((it) => it.name === nChoice.material)

      if (groupIdx > -1) {
        const variant = groups[groupIdx].options.findIndex(
          (it) => it.variant === nChoice.variant
        )
        if (variant === -1) {
          groups[groupIdx].options.push({
            material_variant: `${choice.material}__${choice.variant}`,
            variant: choice.variant,
            choices: [choice],
          })
        } else {
          groups[groupIdx].options[variant].choices.push(choice)
        }
      } else {
        groups.push({
          name: choice.material || '',
          valueUnit: choice.unit_of_measure,
          options: [
            {
              material_variant: `${choice.material}__${choice.variant}`,
              variant: choice.variant,
              choices: [choice],
            },
          ],
        })
      }
    })
    return groups
  }
}

export const getStarRanges = (assessment: number) => {
  let starRagnes = []
  for (let i = 0.6; i < 1.5; i += 0.1) {
    starRagnes.push(assessment * i)
  }
  return starRagnes
}

const star: any = {
  '-1': 5,
  0: 4.5,
  1: 4,
  2: 3.5,
  3: 3,
  4: 2.5,
  5: 2,
  6: 1.5,
  7: 1,
  8: 1,
}

export const getStarRating = (
  threeStarBenchmark?: number | null,
  totalCO2?: number
) => {
  if (!threeStarBenchmark || !totalCO2) return 0
  const ranges = getStarRanges(threeStarBenchmark)
  let rangeIndex: number = -1

  ranges.forEach((it, index) => {
    if (totalCO2 > it) {
      rangeIndex = index
      return
    }
  })

  return star[rangeIndex]
}

export const formatSectionData = (
  section: CalculatorSection,
  projectLocation: string,
  projectBuildingSubType: string,
  benchmarks: Benchmark[]
) => {
  let questions: Question[] = []
  if (benchmarks && benchmarks?.length > 0) {
    let benchmark = benchmarks.find(
      (it: Benchmark) =>
        it.building_type === projectBuildingSubType &&
        it.section === section.slug
    )
    if (!!benchmark) {
      const carbon_data = lodash.get(benchmark, 'three_star_benchmark.carbon_data');
      if (!!carbon_data) {
        const data = carbon_data.find(
          (it: BenchmarkCarbonData) => it.location === projectLocation
        )
        if (data) {
          section.threeStarBenchmark = data.carbon_rating
          section.scope = data.scope
        }
      }

      const eco_data = lodash.get(benchmark, 'ecological_benchmark.carbon_data');
      if (!!eco_data) {
        const data = eco_data.find(
          (it: BenchmarkCarbonData) => it.location === projectLocation
        )
        if (data) {
          section.ecologicalBenchmark = data.carbon_rating
        }
      }
    }
  }
  section.questions.forEach((question) => {
    if (question.sub_questions.length > 0) {
      questions = questions.concat(question.sub_questions)
    } else {
      questions.push(question)
    }
  })
  const questionsGroup = questions.filter(
    (it) => it.sub_questions !== undefined
  )
  const subQuestions = questions.filter((it) => it.sub_questions === undefined)
  section.requiredQuestionIds = _uniqBy(
    questionsGroup.filter((it) => it.required),
    'id'
  ).map((it) => it.id)
  section.questionIds = _uniqBy(questionsGroup, 'id').map((it) => it.id)
  section.subQuestionIds = _uniqBy(subQuestions, 'id').map((it) => it.id)
  section.requiredSubQuestionIds = _uniqBy(
    subQuestions.filter((it) => it.required),
    'id'
  ).map((it) => it.id)
}

export const getQuestionAnswserDetails = (
  section: CalculatorSection,
  results?: AssessmentAnswer[]
) => {
  const data: QuestionWithAnswers[] = []

  const answerQuestions = _groupBy(
    results?.filter((it) => it.question_id && !it.sub_question_id),
    (answer) => answer.question_id
  )

  const answerSubQuestions = _groupBy(
    results,
    (answer) => answer.sub_question_id
  )

  section.questionIds.forEach((id) => {
    if (answerQuestions[id]) {
      data.push({
        name: answerQuestions[id][0].question_text || '',
        answers: answerQuestions[id],
      })
    }
  })

  section.subQuestionIds.forEach((id) => {
    if (answerSubQuestions[id]) {
      data.push({
        name: answerSubQuestions[id][0].question_text || '',
        answers: answerSubQuestions[id],
      })
    }
  })

  return data
}

export const getMenuItemsFromSections = (
  cSections: CalculatorSection[],
  projectLocation: string,
  projectBuildingType: string,
  benchmarks: Benchmark[],
  results?: AssessmentAnswer[]
) => {
  if (cSections.length === 0) return []
  let menuItems: CalculatorSection[] = _cloneDeep(cSections).filter(
    (it) => !it.parent_section
  )
  let nSections: CalculatorSection[] = []
  let carbonBreakdown: CarbonBreakdownSection[] = []

  let index = 0
  menuItems.forEach((it) => {
    it.index = index
    formatSectionData(it, projectLocation, projectBuildingType, benchmarks)
    nSections.push(it)
    carbonBreakdown.push({
      section: it.name,
      questions: getQuestionAnswserDetails(it, results),
    })
    const childSections = _cloneDeep(cSections).filter(
      (child) => child.parent_section === it.name
    )
    it.childs = childSections
    if (it.childs.length > 0) {
      it.childs.forEach((child) => {
        formatSectionData(
          child,
          projectLocation,
          projectBuildingType,
          benchmarks
        )
        nSections.push(child)
        carbonBreakdown.push({
          section: child.name,
          questions: getQuestionAnswserDetails(child, results),
        })
        child.index = index + 1
        index += 1
      })
      index += 1
    } else {
      index += 1
    }
  })

  return {
    menuItems,
    sections: nSections,
    carbonBreakdown,
  }
}

export const getParentQuestionType = (question: Question) => {
  if (question.sub_questions && question.sub_questions.length > 0) {
    return question.sub_questions.map((it) => it.question_type)
  }
  return question.question_type
}

export const roudedStar = (avgStar?: number) => {
  if (!avgStar) return 0
  let nStarIndex = -1
  const ranges = [5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1]
  ranges.forEach((it, index) => {
    if (avgStar < it) {
      nStarIndex = index
      return
    }
  })
  return star[nStarIndex]
}

export const getValueUnitText = (valueUnit?: ValueUnit | null) => {
  if (!valueUnit) return ''
  return valueUnit === 'm2' ? (
    <>
      m<sup>2</sup>
    </>
  ) : valueUnit === 'm3' ? (
    <>
      m<sup>3</sup>
    </>
  ) : valueUnit === 't' || valueUnit === 'kl' || valueUnit === 'l' ? (
    valueUnit.toUpperCase()
  ) : (
    valueUnit
  )
}

export const getBuildingCarbonBreakdown = (
  carbonBreakdown: CarbonBreakdownSection[],
  isKgCo2Unit?: boolean,
) => {
  const buildingCarbonBreakdown = carbonBreakdown.find(item => item.section.toLowerCase() === "buildings")
  if (!buildingCarbonBreakdown) {
    return []
  }

  if (!buildingCarbonBreakdown.questions) {
    return []
  }

  const buildingCarbonBreakdownData = []
  for (let question of buildingCarbonBreakdown.questions) {
    if (!!question.answers) {
      for (let answer of question.answers) {
        buildingCarbonBreakdownData.push({
          carbonCost: convertToneToKg(answer.carbon_cost, isKgCo2Unit),
          detailedMaterial: answer.detailed_material,
          material: answer.material,
          referenceId: answer.reference_id,
        })
      }
    }
  }

  return buildingCarbonBreakdownData
}

export const getPrecinctMaterialTableBreakdown = (
  sections: CalculatorSection[],
  answers?: AssessmentAnswer[],
  isKgCo2Unit?: boolean,
) => {
  let materialChartData = _cloneDeep(sections)
  
  const getAnswersByQuestionId = (id: number) => {
    return answers?.filter((it) => it.question_id === id)
  }

  materialChartData.forEach((section) => {
    section.carbonCost = 0
    section.carbonCostTBA = 0
    section.lifetimeCost = 0
    section.lifetimeCostTBA = 0
    section.ecoDemandTotal = 0
    section.ecoDemandTBA = 0

    section.questions.forEach((question) => {
      const answersData = getAnswersByQuestionId(question.id)

      if (answersData && answersData.length > 0) {
        answersData?.forEach((it: any) => {
          section.carbonCost += it.carbon_cost
          section.carbonCostTBA += (it.carbon_cost_units && it.carbon_cost_units[0]) || 0
          section.lifetimeCost += it.lifetime_carbon_cost
          section.lifetimeCostTBA += (it.lifetime_cost_units && it.lifetime_cost_units[0]) || 0
          section.ecoDemandTotal += it.eco_demand_total
          section.ecoDemandTBA += (it.eco_demand_units && it.eco_demand_units[0]) || 0
        })
      }
    })

    if (section.childs && section.childs.length > 0) {
      section.childs.forEach((child) => {
        child.carbonCost = 0
        child.carbonCostTBA = 0
        child.lifetimeCost = 0
        child.lifetimeCostTBA = 0
        child.ecoDemandTotal = 0
        child.ecoDemandTBA = 0

        child.questions.forEach((cQuestion) => {
          const cAnswersData = getAnswersByQuestionId(cQuestion.id)

          if (cAnswersData && cAnswersData.length > 0) {
            cAnswersData?.forEach((it: any) => {
              child.carbonCost += it.carbon_cost
              section.carbonCost += it.carbon_cost
              child.carbonCostTBA += (it.carbon_cost_units && it.carbon_cost_units[0]) || 0
              section.carbonCostTBA += (it.carbon_cost_units && it.carbon_cost_units[0]) || 0

              child.lifetimeCost += it.lifetime_carbon_cost
              section.lifetimeCost += it.lifetime_carbon_cost
              child.lifetimeCostTBA += (it.lifetime_cost_units && it.lifetime_cost_units[0]) || 0
              section.lifetimeCostTBA += (it.lifetime_cost_units && it.lifetime_cost_units[0]) || 0

              child.ecoDemandTotal += it.eco_demand_total
              section.ecoDemandTotal += it.eco_demand_total
              child.ecoDemandTBA += (it.eco_demand_units && it.eco_demand_units[0]) || 0
              section.ecoDemandTBA += (it.eco_demand_units && it.eco_demand_units[0]) || 0
            })
          }
        })
      })
    }
  })

  return materialChartData.map((it) => {
    let childs: any = []
    if (it.childs) {
      childs = it.childs.map((child) => {
        return {
          name: child.name,
          carbonCost: convertToneToKg(child.carbonCost, isKgCo2Unit),
          carbonCostTBA: convertToneToKg(child.carbonCostTBA, isKgCo2Unit),
          lifetimeCost: convertToneToKg(child.lifetimeCost, isKgCo2Unit),
          lifetimeCostTBA: convertToneToKg(child.lifetimeCostTBA, isKgCo2Unit),
          ecoDemandTotal: child.ecoDemandTotal,
          ecoDemandTBA: child.ecoDemandTBA,
          threeStarBenchmark: convertToneToKg(child.threeStarBenchmark, isKgCo2Unit),
          scope: it.scope,
          colour: child.colour,
          isOperational: child.calculation_type == 'operational',
          sectionType: child.section_type,
          ecologicalBenchmark: child.ecologicalBenchmark,
        }
      })
    }

    return {
      name: it.name,
      carbonCost: convertToneToKg(it.carbonCost, isKgCo2Unit),
      carbonCostTBA: convertToneToKg(it.carbonCostTBA, isKgCo2Unit),
      lifetimeCost: convertToneToKg(it.lifetimeCost, isKgCo2Unit),
      lifetimeCostTBA: convertToneToKg(it.lifetimeCostTBA, isKgCo2Unit),
      ecoDemandTotal: it.ecoDemandTotal,
      ecoDemandTBA: it.ecoDemandTBA,
      threeStarBenchmark: convertToneToKg(it.threeStarBenchmark, isKgCo2Unit),
      scope: it.scope,
      colour: it.colour,
      isOperational: it.calculation_type == 'operational',
      sectionType: it.section_type,
      ecologicalBenchmark: it.ecologicalBenchmark,
      childs: childs,
    }
  })
}